@import '../../styles/common/common.scss';

.container {
    position: relative;
    overflow: hidden;
    z-index: 0;
    background-color: var(--utmb-color-ws-primary);

    .img_wrapper {
        position: absolute;
        z-index: -1;
        bottom: 0;
        right: 0;
        height: 100%;
        width: 100%;
        bottom: -25%;
        right: 0;

        @include bp(sm) {
            bottom: -50%;
        }

        @include bp(md) {
            bottom: -40%;
        }

        @include bp(lg) {
            bottom: -10%;
        }
    }

    .full_height {
        min-height: rem(730px);
        height: rem(730px);

        @media (max-width: 992px) {
            min-height: rem(650px);
            height: rem(650px);
        }

        @include bp(lg) {
            min-height: rem(600px);
            height: rem(600px);
            bottom: 0;
            right: 0;
        }

        .cya_row {
            height: 100% !important;
            justify-content: space-evenly;

            .left_side {
                align-self: center;

                .title,
                .subtitle {
                    color: var(--utmb-color-ws-secondary);
                }

                .title {
                    text-transform: uppercase;
                    line-height: rem(40px);
                    margin-bottom: rem(15px);

                    @include bp(lg) {
                        margin-bottom: rem(30px);
                        padding-right: rem(60px);
                    }
                }

                .subtitle {
                    line-height: rem(20px);

                    @include bp(lg) {
                        padding-right: rem(30px);
                    }
                }

                .link {
                    text-decoration: none !important;
                    width: 100px !important;
                }
            }

            .right_side {
                display: flex;
                justify-content: center;
                align-items: center;

                @include bp(lg) {
                    justify-content: flex-end;
                }

                .link {
                    text-decoration: none !important;
                    margin-right: 0 !important;
                }
            }
        }
    }
}
